import React from "react";
import Logo from "../logo.svg";

class NavItem extends React.Component {
    render() {
        return (
            <a href={this.props.href} aria-label={this.props.children} onClick={() => {if (this.props.open) this.props.toggleMenu()}} className="p-2 font-bold text-[#2B2B2B]">{this.props.children}</a>
        )
    }
}

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false, posFix: false};
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({open: !this.state.open})
    }

    render() {
        // const downScroll = this.props.scrollDir === "down";
        const curY = this.props.scrollY;
        let posClass;
        if (curY < 100) {
            posClass = "absolute top-0";
        } else if (curY > 100 && curY < window.innerHeight / 2) {
            posClass = "absolute -top-14";
        } else if (curY < window.innerHeight / 1.5) {
            posClass = "fixed -top-14";
        } else {
            posClass = "fixed top-0";
        }
        return (
        <nav id="navbar" style={{transition: 'height 0.3s ease, top 0.3s ease'}} className={"lg:h-14 w-full flex flex-row flex-wrap " +
        "place-items-center content-start justify-center z-50 bg-transparent px-5 lg:px-20 " + posClass + 
        (this.state.open ? ' h-full' : ' h-14')}>
            {(curY > 100 || this.state.open) &&
                <div style={{zIndex: -1}} className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-90 bg-cover bg-top"></div>
            }
            <img id="logo" className="self-center mr-auto mt-1 h-10" src={Logo} alt="Logo" />
            <button aria-label="Toggle Menu" onClick={this.toggleMenu} className="h-10 w-10 mt-2 relative lg:hidden">
                <div className={"bg-black absolute left-1 right-1 h-1 rounded-sm transition-all " +
                                (this.state.open ? "top-1/2 transform -translate-y-1/2 rotate-45" : "top-2")}></div>
                <div className={"bg-black absolute left-1 right-1 h-1 rounded-sm transition-all top-1/2 transform -translate-y-1/2 " +
                                (this.state.open ? 'opacity-0' : '')}></div>
                <div className={"bg-black absolute left-1 right-1 h-1 rounded-sm transition-all " +
                                (this.state.open ? "bottom-1/2 transform translate-y-1/2 -rotate-45": "bottom-2")}></div>
            </button>
            <div className={"flex-col content-center justify-center w-full lg:w-auto lg:flex lg:flex-row mt-2 gap-3" +
                            (this.state.open ? ' flex' : ' hidden')}>
                <NavItem toggleMenu={this.toggleMenu} open={this.state.open} href="#home">Home</NavItem>
                <NavItem toggleMenu={this.toggleMenu} open={this.state.open} href="#solutions">Our Solutions</NavItem>
                <NavItem toggleMenu={this.toggleMenu} open={this.state.open} href="#overview">Overview</NavItem>
                <NavItem toggleMenu={this.toggleMenu} open={this.state.open} href="#team">Team</NavItem>
                <NavItem toggleMenu={this.toggleMenu} open={this.state.open} href="#contact">Contact Us</NavItem>
            </div>
        </nav>)
    }
}

export default Navbar;