import React from "react";

class Section extends React.Component {
    render() {
        return (
            <div style={this.props.style || {}} className={this.props.className || "min-h-screen relative"}>
                {/* eslint-disable-next-line */}
                <a href="#" className="absolute" name={this.props.name} style={{top: -3.5 + 'rem'}}></a>
                {this.props.children}
            </div>
        )
    }
}

export default Section